.card {
  background-color: #ffffff;
  width: 200px;
  height: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px;
  border-radius: 5px;
  transition: transform 0.5s ease;
}

.card:hover {
  transform: scale(1.1);
  box-shadow: rgba(242, 237, 237, 0.2) 2px 4px 8px;
}
