@tailwind base;
@tailwind components;
@tailwind utilities;

html {
  scroll-behavior: smooth;
}

body {
  background-color: #0f172a;
  color: #e2e8f0;
}

.img-box {
  margin-top: -4rem;
}

.illustration {
  transition: transform 0.6s ease;
}

.illustration:hover {
  transform: scale(1.1);
}

.tools:hover {
  text-shadow: rgba(242, 237, 237, 0.2) 4px 6px 11px;
}

.menu li:hover {
  color: #66cccc;
}

.tools-fade {
  scale: 0.8;
  opacity: 0;
  animation: fade-in linear forwards;
  animation-timeline: view();
  animation-range: contain 100px 400px;
}

.auto-scroll {
  overflow: hidden;
  /* overflow-x: scroll; */
  width: max-content;
  flex-wrap: nowrap;
  animation: scrolling linear 60s;
}

.intro-fade-out {
  animation: fade-out linear;
  animation-timeline: view();
  animation-range: exit -200px;
}

.work-slide-right {
  transform: translateY(-50%);
  animation-timeline: view();
  animation-range: exit -200px;

  animation: slideRight linear forwards;
  opacity: 0;
}

@keyframes slideRight {
  0% {
    opacity: 0;
    transform: translateX(-100%);
  }
  100% {
    opacity: 1;
    transform: translateX(0);
  }
}

@keyframes fade-out {
  to {
    opacity: 0;
  }
}

@keyframes scrolling {
  to {
    transform: translate(calc(-50% -0.5rem));
  }
}

@keyframes fade-in {
  to {
    scale: 1;
    opacity: 1;
  }
}

@media only screen and (max-width: 1024px) {
  .img-box {
    margin-top: 1rem;
  }
}
