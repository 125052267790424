.date {
  color: #94a3b8;
  font-size: 0.7rem;
  width: 100px;
}

.company {
  font-weight: 600;
  color: #e2e8f0;
}

.description {
  font-size: 0.8rem;
  color: #e2e8f0;
}

.tool {
  display: inline-block;
  padding: 0.3rem 0.5rem;
  border-radius: 9999px;
  color: #66cccc;
  background-color: rgba(45, 212, 191, 0.1);
  font-size: 0.7rem;
  font-weight: 500;
  text-align: center;
}

.workCard {
  border: 1px solid #cfe4ff2d;
  /* box-shadow: rgba(242, 237, 237, 0.2) 4px 6px 11px; */
  padding: 4%;
  border-radius: 5px;
  transition: transform 0.6s ease;
  background-color: #0f172a;
}

.workCard:hover {
  box-shadow: rgba(242, 237, 237, 0.2) 4px 6px 11px;
}
