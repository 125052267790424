.skill {
  display: inline-block;
  padding: 0.5rem 1rem;
  border-radius: 15px;
  color: #66cccc;
  background-color: rgba(45, 212, 191, 0.1);
  font-size: 0.875rem;
  font-weight: 500;
  text-align: center;
  transition: transform 0.5s ease;
}

.skill:hover {
  transform: scale(1.1);
  box-shadow: rgba(242, 237, 237, 0.2) 2px 4px 8px;
}
