.container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
}

.text {
  font-size: 72px;
  opacity: 0;
  transition: opacity 0.5s ease-in-out;
}

.text.show {
  opacity: 1;
  animation: slideIn 2s forwards, fadeOut 1s forwards 3s;
}

.h1 {
  font-size: 5rem;
  width: 100%;
  font-weight: bold;
  letter-spacing: 5px;
  color: #0e3742;
  text-transform: uppercase;
  width: 100%;
  -webkit-box-reflect: below 1px linear-gradient(transparent, #0004);
  line-height: 0.7em;
  outline: none;
  animation: animate 5s linear infinite;
  text-align: center;
}

.h6 {
  font-size: 1.2rem;
  color: #94a3b8;
}

@keyframes animate {
  0%,
  18%,
  20%,
  50.1%,
  60%,
  65.1%,
  80%,
  90.1%,
  92% {
    color: #0e3742;
    text-shadow: none;
  }
  18.1%,
  20.1%,
  30%,
  50%,
  60.1%,
  65%,
  80.1%,
  90%,
  92.1%,
  100% {
    color: #fff;
    text-shadow: 0 0 10px #03bcf4, 0 0 20px #03bcf4, 0 0 40px #03bcf4,
      0 0 80px #03bcf4, 0 0 160px #03bcf4;
  }
}

@keyframes slideIn {
  0% {
    transform: translateX(-100%);
  }
  100% {
    transform: translateX(0);
  }
}

@keyframes fadeOut {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

@media only screen and (max-width: 600px) {
  .text {
    font-size: 40px;
  }
}
