.h1 {
  font-size: 5rem;
  width: 100%;
  font-weight: bold;
  letter-spacing: 5px;
  color: #0e3742;
  text-transform: uppercase;
  width: 100%;
  -webkit-box-reflect: below 1px linear-gradient(transparent, #0004);
  line-height: 0.7em;
  outline: none;
  animation: animate 5s linear infinite;
  text-align: center;
}

@keyframes animate {
  0%,
  18%,
  20%,
  50.1%,
  60%,
  65.1%,
  80%,
  90.1%,
  92% {
    color: #0e3742;
    text-shadow: none;
  }
  18.1%,
  20.1%,
  30%,
  50%,
  60.1%,
  65%,
  80.1%,
  90%,
  92.1%,
  100% {
    color: #fff;
    text-shadow: 0 0 10px #03bcf4, 0 0 20px #03bcf4, 0 0 40px #03bcf4,
      0 0 80px #03bcf4, 0 0 160px #03bcf4;
  }
}

@media only screen and (max-width: 600px) {
  .h1 {
    font-size: 2rem;
    line-height: 0.8em;
  }
}
